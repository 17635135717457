import React, { useEffect, useState} from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import ArtistView from './ArtistView';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function ArtistTable({artists, getArtists}) {

  const [open, setOpen] = useState(false);
  const [id, setId] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [number, setNumber] = useState("")
  const [youtube, setYoutube] = useState("")
  const [img, setImg] = useState("")


  const handleOpen = (artist) => {
    const {_id, name, email, number, youtube, img} = artist;
    console.log(artist)
    setOpen(true)
    setId(_id);setName(name);setEmail(email);setNumber(number);setYoutube(youtube);setImg(img)
  }
  const handleClose = () =>{
    setId("");setName("");setEmail("");setNumber("");setYoutube("");setImg("")
    setOpen(false)
  } ;


    function createData(_id, img, name, email, number, youtube) {
        return {_id, img, name, email, number, youtube };
      }
    
    let sorted = artists.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)


    let rows = sorted.map((artist)=>{
        const {_id, img, name, email, number, youtube} = artist;

        return createData(_id, img, name, email, number, youtube)
    })


  
      
  return (
    <TableContainer component={Paper}>

    <ArtistView 
    name={name} setName={setName} email={email} number={number} setNumber={setNumber} id={id}
    img={img} setImg={setImg} youtube={youtube} setYoutube={setYoutube}
    handleClose={handleClose} open={open} getArtists={getArtists}/>

    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="left">Artist</TableCell>
          <TableCell align="left">Email</TableCell>
          <TableCell align="left">Number</TableCell>
          <TableCell align="left">Youtube</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          rows.map((row) => (
          <TableRow onClick={()=>{handleOpen(row);}}
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            <Avatar alt={row.name} src={row.img} />

            </TableCell>
            <TableCell align="left">{row.name}</TableCell>
            <TableCell align="left">{row.email}</TableCell>
            <TableCell align="left">{row.number}</TableCell>
            <TableCell align="left">{row.youtube ? <a href={row.youtube} target='_blank'><YouTubeIcon sx={{color:'red'}} /></a>: <YouTubeIcon sx={{color:'grey'}}/>}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  )
}
