import React, { useState, useEffect } from 'react'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import './Artists.css'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import axios from 'axios'
import { toast } from 'react-toastify'
import { createUserWithEmailAndPassword } from "firebase/auth";
import { artistAuth} from './Firebase'
import ArtistTable from './ArtistTable';
import { useAuth } from './Auth/AuthContext';

export default function Artists() {
  const { currentUser } = useAuth()

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [youtube, setYoutube] = useState("");
  const [artists, setArtists] = useState([])
  const [loading, setLoading] = useState(false)

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(()=>{
    getArtists()
  },[])

  const getArtists = () =>{
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API}/artist/get-artists`, {headers: {'AuthToken': currentUser.accessToken}})
    .then((res)=>{
      setArtists(res.data)
      setLoading(false)

    })
    .catch((e)=>{console.log(e);setLoading(false)})
  }

  const handleSave = () =>{
    if(!name || !email || !number){
      toast.error("Please add name, email and number")
    } else {

      createUserWithEmailAndPassword(artistAuth, email, "password")
        .then((userCredential) => {
          const user = userCredential.user;

          axios.post(`${process.env.REACT_APP_API}/artist/add-new`, {_id: user.uid, name, email, number, youtube, img:"https://firebasestorage.googleapis.com/v0/b/suited-f85ab.appspot.com/o/singer.png?alt=media&token=61a35077-fcfa-45f9-a30f-dd7c24d6f516"}, {headers: {'AuthToken': currentUser.accessToken}})
          .then(()=>{
            toast.success("Successfully added")
            handleClose()
            emailArtist(email, name)
            getArtists()
            setName("");setEmail("");setNumber("");setYoutube("")
          })
          .catch((e)=>console.log(e))
        })
        .catch((error) => {
          const errorMessage = error.message;
          toast.error(errorMessage)
        });
      }
  }

  const emailArtist = (artistEmail, artistName) =>{
      axios.post(`${process.env.REACT_APP_API}/email/new-artist`, {email: artistEmail, name: artistName}, {headers: {'AuthToken': currentUser.accessToken}})
      .then(()=>{
        console.log("Email sent")
      })
      .catch((e)=>console.log(e))
    }

  return (
    <main id='artist-container'> 
      <Fab sx={{backgroundColor: '#395076'}} aria-label="add" onClick={handleOpen}>
        <AddIcon sx={{color: 'white'}} />
      </Fab>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add New Artist
          </Typography>
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Artist Name' value={name} onChange={(e)=>setName(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Phone Number (inc. +44)' value={number} onChange={(e)=>setNumber(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Youtube Link' value={youtube} onChange={(e)=>setYoutube(e.target.value)}/>
          <Button sx={{backgroundColor: '#395076'}} variant='contained' onClick={handleSave}>Save</Button>
        </Box>
      </Modal>

      <div>

        <h3>Artists</h3>
        {loading ? 
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>: 
        artists.length < 1 ?
        <p>No artists added</p> :
       <ArtistTable artists={artists} getArtists={getArtists}/>}

      </div>

      
    </main>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: '90%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow:'scroll',
};
