import React, {useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list';
import axios from 'axios'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField'
import { toast } from 'react-toastify'
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useAuth } from './Auth/AuthContext';
import moment from 'moment-timezone'

export default function Calendar() {
  const [events, setEvents] = useState([])
  const [applied, setApplied] = useState([])

  const [title, setTitle] = useState("")
  const [artistFee, setArtistFee] = useState("")
  const [venueFee, setVenueFee] = useState("")
  const [notes, setNotes] = useState("")
  const [setTimes, setSetTimes] = useState("")
  const [equipment, setEquipment] = useState("")
  const [id, setId] = useState("")
  const [newItem, setNewItem] = useState("")
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [date, setDate] = useState("")

  const { currentUser} = useAuth();

//modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () =>{ 
    setOpen(false);
  setArtist("");setSetTimes([]);setEquipment([]);setNotes("");setArtistFee("");setVenueFee("")}
  

  //Artist select
  const [artists, setArtists] = useState([])
  const [artist, setArtist] = useState("")
  const [artistName, setArtistName] = useState("")
  const [artistEmail, setArtistEmail] = useState("")
  const [artistNumber, setArtistNumber] = useState("")

  useEffect(()=>{
    getEvents();
    getArtists();
  },[])

  const getEvents = () =>{
    axios.get(`${process.env.REACT_APP_API}/event/get-events`, {headers: {'AuthToken': currentUser.accessToken}})
    .then((res)=>setEvents(res.data))
    .catch((e)=>console.log(e))
  }

  const getArtists = () =>{
    axios.get(`${process.env.REACT_APP_API}/artist/get-artists`, {headers: {'AuthToken': currentUser.accessToken}})
    .then((res)=>{
      let sorted = res.data.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)

      setArtists(sorted)
    })
    .catch((e)=>console.log(e))
  }

  const handleChipDelete = (itemToDelete) => () => {
    setEquipment((item) => item.filter((item) => item.key !== itemToDelete.key));
  };
  const addItem = (e)=>{
    if(e.key === 'Enter'){
      console.log(newItem)
      setEquipment([...equipment, {key: equipment[equipment.length-1].key+1, label: newItem}])
      setNewItem("")
    }
  }
  const removeSet = (set) =>{
    setSetTimes((item) => item.filter((item) => item.key !== set.key));
  } 

  const addSet = () =>{
    if(!from || !to){
      toast.error("Please add from and to time")
    } else {
      let key = setTimes.length < 1 ? 1 : setTimes[setTimes.length-1].key+1
      setSetTimes([...setTimes, {key,from, to}])
      setTo("");setFrom("")
    }
  }

  const handleClick = (info) =>{
    console.log(info.event)
    const {title} = info.event;
    const { artistId, setTimes, equipment, artistFee, venueFee, notes, _id, applied} = info.event.extendedProps;

    setOpen(true)
    setDate(moment(info.event.start).format('YYYY-MM-DD'))
    setTitle(title);setArtistFee(artistFee);setVenueFee(venueFee);setNotes(notes);setSetTimes(setTimes);setEquipment(equipment);setId(_id);setArtist(artistId); setApplied(applied)
  }

  const deleteEvent = () =>{
    if(window.confirm("Are you sure you want to delete event?")){
      axios.post(`${process.env.REACT_APP_API}/event/delete`, {_id: id}, {headers: {'AuthToken': currentUser.accessToken}})
      .then((res)=>{
        toast.success("Successfully deleted")
        handleClose();getEvents();
      })
      .catch((e)=>console.log(e))
  }
  }

 const updateEvent = () =>{
  axios.post(`${process.env.REACT_APP_API}/event/update`, {_id: id, payload:{start: moment(`${date}T${setTimes[0].from}`).tz("Europe/London").format(), artistFee, venueFee, setTimes, equipment, notes, artistId: artist, artistName, artistEmail, artistNumber}}, {headers: {'AuthToken': currentUser.accessToken}})
  .then((res)=>{
    console.log(res.data)
    toast.success("Successfully updated")
    handleClose();getEvents();
  })
  .catch((e)=>console.log(e))
  }

  const checkBoardwalk = () =>{
    if(!artist){
      updateEvent()
    } else if(artist == 'riztdZStE4fmdXJIILut4Za5lFB3' || artist == 'iDFjp2Bm7gWNqxjNDjjoEHnm7Ur1' || artist == 'dKxUbDnK6VMQXHuHMFKyoAWWvgz2') {
      axios.post(`${process.env.REACT_APP_API}/event/check-boardwalk`, {artistId: artist, date, eventId: id}, {headers: {'AuthToken': currentUser.accessToken}})
      .then((res)=>{
        console.log(res.data)
        if(res.data.length < 1){
          updateEvent()
        } else {
          if(window.confirm("There's a conflict with Boardwalk Duo, are you sure you want to continue")){
            updateEvent()
          }
        }
      })
      .catch((e)=>console.log(e))
    } else {
      checkArtist();
    }
  }

  const checkArtist = () =>{
    if(artist){
      axios.post(`${process.env.REACT_APP_API}/event/check-artist`, {artistId: artist, date, eventId: id}, {headers: {'AuthToken': currentUser.accessToken}})
      .then((res)=>{
        console.log(res.data)
        if(res.data.length < 1){
          updateEvent()
        } else {
          if(window.confirm("Artist is already gigging, are you sure you want to add them?")){
            updateEvent()
          }
        }
      })
      .catch((e)=>console.log(e))

    } else {
      updateEvent()
    }
  }

  useEffect( ()=>{
    if(artist){
    let info = getInfo(artist)
      console.log(info)
      setArtistName(info[0].name);setArtistEmail(info[0].email);setArtistNumber(info[0].number)  }
  },[artist])

  const getInfo = (id) =>{
    let info = artists.filter(choice=> choice._id === id )
    return info
  }

  return (
    <div>
        <FullCalendar
                plugins={[ dayGridPlugin, listPlugin ]}
                initialView="dayGridMonth"
                headerToolbar={{center:'dayGridMonth,listMonth'}}
                events={events}
                eventClick={function(info){handleClick(info)}}
                contentHeight={'100vh'}
            />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
      
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Artist</InputLabel>
            <Select sx={{my:1}} labelId="demo-simple-select-label" id="demo-simple-select" value={artist} label="Artist" onChange={(e)=>setArtist(e.target.value)}>
              <MenuItem value={""}>Select Artist</MenuItem>
              {artists.length > 0 && artists.map((artist, i)=>{
                return  <MenuItem key={i} value={artist._id}>{artist.name}</MenuItem>
              })}
            </Select>
          </FormControl>
          {applied.length > 0 &&
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Applied</InputLabel>
            
              <Select sx={{my:1}} labelId="demo-simple-select-label" id="demo-simple-select"  label="Applied" onChange={(e)=>setArtist(e.target.value)}>
                <MenuItem value={""}>Select Artist</MenuItem>
                {applied.map((artist, i)=>{
                let name = artists.filter(musician=>musician._id === artist)
                  return  <MenuItem key={i} value={artist}>{name[0].name}</MenuItem>
                })}
            </Select>
          </FormControl>
          }

          <TextField variant='outlined' fullWidth sx={{my:1}} label='Artist Fee' value={artistFee} onChange={(e)=>setArtistFee(e.target.value)} />
          <TextField variant='outlined' fullWidth sx={{my:1}} label='Venue Fee' value={venueFee} onChange={(e)=>setVenueFee(e.target.value)} />
          <TextField variant='outlined' fullWidth sx={{my:1}} multiline rows={2} label='Booking Notes' value={notes} onChange={(e)=>setNotes(e.target.value)} />
          
          <label>From</label>
            <input type='time' style={{height: '40px', width: '100px', margin: '0 5px', padding: '5px'}} value={from} onChange={(e)=>setFrom(e.target.value)}/>
            <label>To</label>
            <input type='time' style={{height: '40px', width: '100px', margin: '0 5px', padding: '5px'}} value={to} onChange={(e)=>setTo(e.target.value)}/>
            <button onClick={addSet} style={{backgroundColor: '#395076', color: 'white', border: 'unset', padding: '5px 10px'}}>Add</button>
              
            {setTimes.length > 0 && setTimes.map((set, i)=>{
              return(
                <div key={i} style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                  <h4 style={{color: '#395076'}}>Set {i+1}</h4>
                  <p style={{color: '#6e767b'}}>{set.from} - {set.to}</p>
                  <DeleteForeverIcon color="error" onClick={()=>removeSet(set)}/>
                </div>
              ) 
            })}
            
          
          <Paper sx={{display: 'flex', justifyContent: 'center',flexWrap: 'wrap', listStyle: 'none', p: 0.5, mb: 2,}} component="ul">
            {equipment.length > 0 && equipment.map((data) => {
              let icon;
              return (
                <ListItem key={data.key}>
                  <Chip icon={icon} label={data.label} onDelete={handleChipDelete(data)} />
                </ListItem>
              );
            })}
                  <input onKeyDown={addItem} value={newItem} onChange={(e)=>setNewItem(e.target.value)}/>
            </Paper>
          <Button variant='contained' sx={{mx:1, backgroundColor:'#395076'}} onClick={checkBoardwalk}>Update</Button>

          <Button variant='contained' sx={{mx:1}} color='error' onClick={deleteEvent}>Delete</Button>

        </Box>
      </Modal>
    </div>
  )
}

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: '95%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign:'center',
  overflow:'scroll',
};
