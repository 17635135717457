import React, { useState} from 'react'
import AdminNav from './AdminNav'
import './AdminUI.css'

import { Outlet } from 'react-router-dom'

export default function AdminUI() {
    const [open, setOpen] = useState(true)


  return (
    <div>
        <AdminNav open={open} setOpen={setOpen}/>
        <main id={open ? 'main-open' : 'main-closed'}>
                <Outlet />
             
        </main>
    </div>
  )
}
