import React, { useState, useEffect} from 'react'
import './AdminUI.css'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessIcon from '@mui/icons-material/Business';
import { Link } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from './Firebase';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify'
import axios from 'axios'
import { CSVLink } from "react-csv";
import moment from 'moment-timezone'
import { adminStorage } from './Firebase';
import { ref, uploadBytes } from "firebase/storage";
import { useAuth } from './Auth/AuthContext';

export default function AdminNav({ open, setOpen}) {
  const { currentUser} = useAuth();
  //Data modal
    const [openModal, setOpenModal] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
  //Img modal
    const [openImgModal, setOpenImgModal] = useState(false);
    const handleImgOpen = () => setOpenImgModal(true);
    const handleImgClose = () => setOpenImgModal(false);
    const [loading, setLoading] = useState(false)
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    const [data, setData] = useState([])
    const logout = () =>{
        signOut(auth).then(() => {
            // Sign-out successful.
          }).catch((error) => {
            // An error happened.
          });
    }

    useEffect(()=>{
        if(to && from){
            handleDownload()
        }
    },[to])

    const handleDownload = (event, done) =>{
        if(from > to){
            toast.error("The from date must be before the to date")
        } else {
            if(!loading){
                setLoading(true)
            }
            axios.post(`${process.env.REACT_APP_API}/event/get-data`,{from, to}, {headers: {'AuthToken': currentUser.accessToken}})
            .then((res)=>{
                let formatted = res.data.map(obj => {
                    return {...obj, start: new Date(obj.start)};
                  });
                const sorted = formatted.sort(
                    (objA, objB) =>   Number(objA.start)-Number(objB.start)
                  );
                let finalData = sorted.map((event)=>{
                    const { title, artistName, start, venueFee, artistFee} = event;
                    return (
                        {title: title, artistName, start: moment(start).tz("Europe/London").format('DD/MM/YY'), venueFee: venueFee.toString(), artistFee: artistFee.toString()}
                    )
                })
             
                  console.log(finalData)
                setData(finalData)
                setLoading(false)
                
            })
            .catch((e)=>{console.log(e);setLoading(false)
            })
        }
    }


    const headers = [
        { label: "Date", key: "start" },
        { label: "Venue", key: "title" },
        { label: "Artist", key: "artistName" },
        { label: "Venue Fee", key: "venueFee" },
        { label: "Artist Fee", key: "artistFee" }

      ];

      const imgRef = ref(adminStorage, `mobile-homescreen`);
      const uploadImg = (file) =>{
          console.log(imgRef)
          uploadBytes(imgRef, file).then((snapshot) => {
              toast.success("Uploaded")
              handleImgClose()
          });
      }
      

  return (
    <nav id={open ? 'admin-nav-open': 'admin-nav-closed'}>
        
        <img onClick={()=>setOpen(true)} id={open ? 'nav-logo' : 'logo-closed'}
        src={open ? 'https://firebasestorage.googleapis.com/v0/b/suited-f85ab.appspot.com/o/Suited-main-logo-WHITE.png?alt=media&token=5c5acfad-44de-463a-81e0-537270354801'
    : 'https://firebasestorage.googleapis.com/v0/b/suited-f85ab.appspot.com/o/Suited%20Emblem.png?alt=media&token=578fc24c-8fe0-41ea-ba49-0b8eb96164b4'} alt='suited-logo'/>

        <Link to={'/'} className='nav-item'>
            <CalendarTodayIcon sx={{color: '#fff'}}/>
            <p className={open ?'nav-title': 'nav-title-closed'}>Calendar</p>
        </Link>
        <Link to={'/bookings'} className='nav-item'>
            <LibraryMusicIcon sx={{color: '#fff'}}/>
            <p className={open ?'nav-title': 'nav-title-closed'}>Bookings</p>
        </Link>
        <Link to={'/artists'} className='nav-item'>
            <GroupsIcon sx={{color: '#fff'}}/>
            <p className={open ?'nav-title': 'nav-title-closed'}>Artists</p>
        </Link>
        <Link to={'/venues'} className='nav-item'>
            <BusinessIcon sx={{color: '#fff'}}/>
            <p className={open ?'nav-title': 'nav-title-closed'}>Venues</p>
        </Link>
        <Divider/>
        <Link onClick={handleOpen} className='nav-item'>
            <DownloadIcon sx={{color: '#fff'}}/>
            <p className={open ?'nav-title': 'nav-title-closed'}>Download Data</p>
        </Link>
        <Link onClick={handleImgOpen} className='nav-item'>
            <MobileScreenShareIcon sx={{color: '#fff'}}/>
            <p className={open ?'nav-title': 'nav-title-closed'}>Mobile Image</p>
        </Link>

        {open && <div id='btn-container'>
            <button id='log-out-btn' onClick={logout}>Log Out</button>
            <button id='menu-btn' onClick={()=>setOpen(false)}>Close</button>
        </div> 

        
}
      
        <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Download Events
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Select date range:
          </Typography>
          <div>
            <input type='date' value={from} onChange={(e)=>setFrom(e.target.value)}/> <span> - </span>
            <input type='date' value={to} onChange={(e)=>setTo(e.target.value)}/>
          </div>
       
          < CSVLink filename={"suited-events.csv"}  data={data} headers={headers} >
            <Button  variant='contained' sx={{my:2, backgroundColor:'#395076'}} disabled={loading || !data}>
                    Download 
            </Button>
            </CSVLink>

        </Box>
      </Modal>

      <Modal
        open={openImgModal}
        onClose={handleImgClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Upload Mobile Image
          </Typography>
          <img id='mobile-img' src='https://firebasestorage.googleapis.com/v0/b/suited-f85ab.appspot.com/o/mobile-homescreen?alt=media&token=ce4e6a5b-2225-4aa2-89f2-dbcd35a9ec33' alt="Mobile Image"/>
          <input type='file' onChange={(e)=>uploadImg(e.target.files[0])}/>
        </Box>
      </Modal>
    </nav>
  )
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '90%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
    overflow: 'scroll'
  };